import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell viscós de 4 a 10 cm de diàmetre, convexo-campanulat, per passar a pla i amb un mamelló rugós pels voltants. És de color marró o marró terrós. Les làmines són gruixudes, espaiades, blanquinoses, adnades i amb lamel·les. El peu és blanquinós amb tonalitats marrons, cilíndric, fibrós, un poc bulbós a la base i acabat amb una mena d’arrel molt llarga. Les espores són blanques en massa, el·líptiques, de 12-16 x 10-12 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      